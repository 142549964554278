import { AxiosError } from 'axios';
import { useCallback, useState } from 'react';
import { NormalAlert } from '../../components/core/alert-card/alert-cards';
import { Item } from '../../components/ui/dropdownBtn';
import DataTypeContext from './daily-report-context';
import { GetDailyReportRequest } from './daily-report-types';
import DailyReportService from './daily-report.service';

const DailyReportProvider: React.FC = ({ children }) => {
  const [dataTypes, setDataTypes] = useState<Item[]>([]);
  const [businessLines, setBusinessLines] = useState<Item[]>([]);

  const fetchAllDataTypes = useCallback(async () => {
    const { promise, abort } = DailyReportService.getAllDataTypes();

    setDataTypes([]);
    const res = await promise;
    setDataTypes(res);

    return abort;
  }, []);

  const fetchAllBusinessLines = useCallback(async () => {
    const { promise, abort } = DailyReportService.getAllBusinessLines();

    setBusinessLines([]);
    const res = await promise;

    setBusinessLines(res);

    return abort;
  }, []);

  const downloadDailyReport = useCallback(async (request: GetDailyReportRequest) => {
    const { promise, abort } = DailyReportService.downloadDailyReport(request);

    try {
      const res = await promise;
      const fileUrl = window.URL.createObjectURL(new Blob([res.data]));

      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', request.reportName);
      document.body.appendChild(link);
      link.click();

      window.URL.revokeObjectURL(fileUrl);
    } catch (e: any) {
      NormalAlert('Error while trying to create the report');
    }
  }, []);

  const sendToEmailDailyReport = useCallback(async (request: GetDailyReportRequest) => {
    const { promise, abort } = DailyReportService.sendToEmailDailyReport(request);

    try {
      const res = await promise;
      NormalAlert('You will receive your email soon');
    } catch (error: any) {
      const axiosError = error as AxiosError;
      if (axiosError.response) NormalAlert(axiosError.response.data.error);
      else NormalAlert('Error when trying to send the email');
    }
  }, []);

  return (
    <DataTypeContext.Provider
      value={{
        dataTypes,
        businessLines,
        fetchAllDataTypes,
        fetchAllBusinessLines,
        downloadDailyReport,
        sendToEmailDailyReport,
      }}>
      {children}
    </DataTypeContext.Provider>
  );
};

export default DailyReportProvider;
