import { Button } from '@amway/react-components';
import { FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { Col, Form } from 'react-bootstrap';
import { ConfirmGeneralAction, NormalAlert } from '../../../components/core/alert-card/alert-cards';
import { IDistCostForecastSettings } from '../../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings-types';
import distCostForecastSettingsService from '../../../resources/dist-cost-forecast-settings/dist-cost-forecast-settings.service';

interface Props {
  settingsChanged: IDistCostForecastSettings;
  setSettingsChanged: React.Dispatch<React.SetStateAction<IDistCostForecastSettings>>;
}

export default function DistCostForecastSettingsCard({ settingsChanged, setSettingsChanged }: Props) {
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [manualRate, setManualRate] = useState<string>('');
  const [createForecastLoading, setCreateForecastLoading] = useState<boolean>(false);

  useEffect(() => {
    setManualRate(settingsChanged.manualRate.toLocaleString());
  }, [settingsChanged]);

  const createForecast = async () => {
    setCreateForecastLoading(true);
    await distCostForecastSettingsService.createDistCostForecast();
    setCreateForecastLoading(false);
  };

  const onUpdateValues = async () => {
    if (Number(manualRate) == 0) {
      NormalAlert('Cannot set 0 as manual rate');
      return;
    }

    setIsUpdating(true);
    try {
      await distCostForecastSettingsService.editDistCostForecastSettings({
        ...settingsChanged,
        manualRate: Number(manualRate),
      });
      NormalAlert('Dist Cost Forecast Settings edits saved successfully');
    } catch {
      NormalAlert('Error when saving dist cost forecast settings edits');
    }
    setIsUpdating(false);
  };

  return (
    <div className="dist-cost-forecast-settings-card">
      <Col
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          alignItems: 'flex-end',
          marginTop: '-70px',
          marginBottom: '40px',
          gap: '12px',
        }}>
        <Button
          style={{ height: 'fit-content', width: '250px' }}
          onClick={onUpdateValues}
          disabled={isUpdating}
          loading={isUpdating}>
          Save settings
        </Button>
        <Button
          style={{ height: 'fit-content', width: '250px' }}
          onClick={() => {
            ConfirmGeneralAction(
              'Do you really want to create a Dist Cost Forecast for all countries with the "Generate Forecast automatically" tag selected?',
              'Dist Cost Forecast created successfully',
              'Error creating the Dist Cost Forecast',
              createForecast,
            );
          }}
          disabled={createForecastLoading}
          loading={createForecastLoading}>
          Update forecast now
        </Button>
      </Col>
      <div className="dist-cost-settings-opts">
        <div className="opts">
          <div>
            <Typography fontSize="14px" color="detail-gray">
              Automatic Rate
            </Typography>
            <Form.Control className="rate-opt" value={settingsChanged.automaticRate} disabled={true} />
          </div>
          <div>
            <Typography fontSize="14px" color="detail-gray">
              Manual Rate
            </Typography>
            <Form.Control
              className="rate-opt"
              value={manualRate}
              onChange={e => setManualRate(e.target.value.replace(/[^0-9.,]/g, ''))}
            />
          </div>
        </div>
        <div>
          <RadioGroup
            onChange={(e, value) => {
              setSettingsChanged(prev => ({ ...prev, overwriteManual: value === 'true' ? true : false }));
            }}
            style={{ height: '100%' }}>
            <div className="radio-opts">
              <FormControlLabel
                checked={!settingsChanged.overwriteManual}
                value="false"
                control={<Radio />}
                label="Use automatic rate"
                title="Use automatic rate"
              />
              <FormControlLabel
                checked={settingsChanged.overwriteManual}
                value="true"
                control={<Radio />}
                label="Use manual rate"
                title="Use manual rate"
              />
            </div>
          </RadioGroup>
        </div>
      </div>
    </div>
  );
}
