import { Button, Typography } from '@amway/react-components';
import { DeleteOutline } from '@mui/icons-material';
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Accordion, Col, Row } from 'react-bootstrap';
import { NormalAlert } from '../../../../components/core/alert-card/alert-cards';
import DropdownBtn from '../../../../components/ui/dropdownBtn';
import SideWrapper from '../../../../components/ui/side-wrapper';
import useCommon from '../../../../resources/common/common-hook';
import useMacroGraph from '../../../../resources/macro-graph/macro-graph-hook';
import {
  emptyMacroGraphChartLine,
  IComparisonLine,
  initialChartStyle,
  MacroGraphChartLineRequest,
} from '../../../../resources/macro-graph/macro-graph-types';
import { checkEachField, colorOptions, scaleOptions } from '../../utils';
import './index.scss';
import LineAccordionItem from './line-accordion-item';

interface Props {
  isToShow: boolean;
  close: () => void;
  setInitialFetch?: React.Dispatch<React.SetStateAction<boolean>>;
}

const show12MonthIdDiff = 1000;

const comparisonLinesTypeItems = [
  { id: 'none', label: 'None' },
  { id: 'ratio', label: 'Ratio' },
  { id: 'percentage', label: 'Percentage' },
];

const InputDataTab: React.FC<Props> = ({ isToShow, close, setInitialFetch }) => {
  const dataKeyCounter = useRef(0);
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const { businessLines, dataTypesMetadata, countries, fetchAllCountries } = useCommon();
  const [linesToBeCompared, setLinesToBeCompared] = useState<Partial<IComparisonLine & { scale: string }>[]>([]);
  const [comparisonLinesConfig, setComparisonLinesConfig] = useState({
    allToOne: false,
    type: 'none',
  });
  const [chartLines, setChartLines] = useState<MacroGraphChartLineRequest[]>([]);

  const {
    filterGraph,
    setChartStyle,
    chartStyle,
    setGraphTitle,
    setGraphSubtitle,
    setChangedScaleManually,
    setLineIds,
  } = useMacroGraph();

  const handleDeleteLine = useCallback((id: number) => {
    setChartLines(prev => prev.filter(line => line.id !== id));
  }, []);

  const handleAddLine = useCallback(() => {
    dataKeyCounter.current = dataKeyCounter.current + 1;
    setChartLines(prev => {
      if (prev.length === 0) {
        return [{ ...emptyMacroGraphChartLine, id: dataKeyCounter.current }];
      } else {
        const newLine = { ...prev[prev.length - 1], id: dataKeyCounter.current, businessLine: [], category: [] };
        return [...prev, newLine];
      }
    });
    setActiveKey(`${dataKeyCounter.current}`);
  }, []);

  useEffect(() => {
    if (chartLines.length === 0) handleAddLine();
    if (countries.length === 0) fetchAllCountries();
  }, []);

  useEffect(() => {
    setLineIds(
      chartLines.map(line => {
        return line.id;
      }),
    );
  }, [chartLines.length]);

  const newLineConfiguration = useCallback(
    (line: MacroGraphChartLineRequest, is12MonthRollingAvg = false, comparisonIndex?: number) => {
      return {
        lineId: line.id ?? 1,
        countryCodes: line.countriesCodes,
        dataType:
          // checking if this line is a comparison line
          (comparisonIndex || comparisonIndex === 0) &&
          !comparisonLinesConfig.allToOne &&
          linesToBeCompared[comparisonIndex].line1 !== undefined
            ? (chartLines?.[linesToBeCompared[comparisonIndex].line1!]?.dataType as string)
            : line.dataType,
        currencyOptions: {
          year: line.currencyOptions.year,
          month: line.currencyOptions.month,
          type: line.currencyOptions.type,
        },
        is12MonthRollingAvg,
        graphAs: line.graphAs,
        showAsPercentage:
          (comparisonIndex || comparisonIndex === 0
            ? linesToBeCompared[comparisonIndex]?.type === 'percentage'
            : false) ||
          (comparisonLinesConfig.allToOne && linesToBeCompared[0]?.type === 'percentage'),
        scale:
          // checking if this line is a comparison line
          (comparisonIndex || comparisonIndex === 0) && !comparisonLinesConfig.allToOne
            ? String(linesToBeCompared[comparisonIndex]?.scale)
            : line.scale,
      };
    },
    [chartLines, comparisonLinesConfig.allToOne, linesToBeCompared],
  );

  const newLineColors = useCallback((line: MacroGraphChartLineRequest, is12MonthAvg = false) => {
    const getColorIndex = !is12MonthAvg
      ? (line?.id ?? 1) - 1
      : colorOptions.length - 1 - (+(line?.id ?? show12MonthIdDiff) - show12MonthIdDiff);

    return { lineId: line.id, color: colorOptions[getColorIndex] } as { lineId: number; color: string };
  }, []);

  const updateChartLine = useCallback(
    (lines: MacroGraphChartLineRequest[]) => {
      let formmatedLines = [...lines];
      let numberOfResultantComparisonLines = 0;

      if (linesToBeCompared.length > 0) {
        const show12MonthsLines = lines.filter(line => line.show12MonthRollingAvg);
        const linesCreated = lines.length + show12MonthsLines.length;

        const linesToBeCombined: number[] = [];

        linesToBeCompared.forEach(line => {
          if (line.line1 && !linesToBeCombined.includes(line.line1)) {
            linesToBeCombined.push(line.line1);
          }
          if (line.line2 && !linesToBeCombined.includes(line.line2)) {
            linesToBeCombined.push(line.line2);
          }
        }, []);

        // edge case when comparing line1 to line2 and line2 to line1
        if (linesToBeCompared.length >= linesToBeCombined.length) {
          numberOfResultantComparisonLines = linesToBeCompared.length;
        } else {
          numberOfResultantComparisonLines = comparisonLinesConfig.allToOne
            ? linesCreated - 1
            : Math.ceil(linesToBeCombined.length / 2);
        }

        const numberOfLinesAfterCombination =
          linesCreated - linesToBeCombined.length + numberOfResultantComparisonLines;
        const diff = linesCreated - numberOfLinesAfterCombination;

        if (diff > 0) {
          formmatedLines = lines.slice(diff, lines.length);
        } else {
          // add more lines equal to the positive difference
          for (let i = 0; i < Math.abs(diff); i++) {
            formmatedLines.push({ ...emptyMacroGraphChartLine, id: ++dataKeyCounter.current });
          }
        }
      }

      setChartStyle(prev => ({
        ...prev,
        lineConfigurations: formmatedLines.flatMap((line, index) => {
          if (line.show12MonthRollingAvg) {
            return [
              newLineConfiguration(line),
              newLineConfiguration({ ...line, id: (line?.id ?? 0) + show12MonthIdDiff }, true),
            ];
          }
          return newLineConfiguration(line, false, index < numberOfResultantComparisonLines ? index : undefined);
        }),
        lineColors: formmatedLines.flatMap(line => {
          const color = prev.lineColors.find(color => color.lineId === line.id);
          if (color) {
            if (line.show12MonthRollingAvg) {
              return [color, newLineColors({ ...line, id: (line?.id ?? 0) + show12MonthIdDiff }, true)];
            }
            return color;
          }

          if (line.show12MonthRollingAvg) {
            return [newLineColors(line), newLineColors({ ...line, id: (line?.id ?? 0) + show12MonthIdDiff }, true)];
          }

          return newLineColors(line);
        }),
      }));
    },
    [comparisonLinesConfig.allToOne, linesToBeCompared, newLineColors, newLineConfiguration, setChartStyle],
  );

  const handleApply = useCallback(() => {
    const textToShow = checkEachField(
      chartLines,
      linesToBeCompared,
      comparisonLinesConfig.allToOne,
      countries,
      comparisonLinesConfig.type,
    );
    if (textToShow !== '') {
      NormalAlert(textToShow);
      return;
    }

    updateChartLine(chartLines);

    filterGraph({
      calendar: chartStyle.calendars,
      style: chartStyle.styles,
      comparisonLines: linesToBeCompared.map(line => {
        return {
          allToOne: comparisonLinesConfig.allToOne,
          type: comparisonLinesConfig.allToOne ? comparisonLinesConfig.type : line.type,
          line1: chartLines[line?.line1 ?? 0].id,
          line2: chartLines[line?.line2 ?? 0].id,
        } as IComparisonLine;
      }),
      lines: chartLines,
      browserByCorpCode: {
        show: chartStyle.graphOptions.browseByCorpCode,
        lineNumber: chartStyle.graphOptions.browseByCorpCodeLine,
      },
      hasDraftValues: false,
    });

    setInitialFetch?.(true);
  }, [
    chartLines,
    chartStyle.calendars,
    chartStyle.graphOptions.browseByCorpCode,
    chartStyle.graphOptions.browseByCorpCodeLine,
    chartStyle.styles,
    comparisonLinesConfig.allToOne,
    comparisonLinesConfig.type,
    countries,
    filterGraph,
    linesToBeCompared,
    setInitialFetch,
    updateChartLine,
  ]);

  useEffect(() => {
    const textToShow = checkEachField(
      chartLines,
      linesToBeCompared,
      comparisonLinesConfig.allToOne,
      countries,
      comparisonLinesConfig.type,
    );
    if (
      textToShow === '' &&
      chartStyle.graphOptions.browseByCorpCodeLine !== undefined &&
      chartStyle.graphOptions.browseByCorpCodeLine !== null
    ) {
      if (chartStyle.calendars) {
        setChartLines(prev =>
          prev.map(lineRequest => {
            return {
              ...lineRequest,
              dateInterval: {
                ...lineRequest.dateInterval,
                startMonth: chartStyle.calendars === 'fiscal' ? 9 : 1,
                endMonth: chartStyle.calendars === 'fiscal' ? 8 : 12,
              },
            };
          }),
        );
      }
      handleApply();
    }
  }, [chartStyle.calendars, chartStyle.graphOptions.browseByCorpCode, chartStyle.graphOptions.browseByCorpCodeLine]);

  const dataTypeHasBusinessLines = useCallback(
    (dataType: string) => {
      return dataTypesMetadata.find(dataTypeMetadata => dataTypeMetadata.dataType === dataType)?.hasBusinessLine;
    },
    [dataTypesMetadata],
  );

  const handleAddBusinessLines = useCallback(
    (addOnly: boolean) => {
      const businessLinesToNotInclude = ['OTHER', 'CATALOG'];
      const filteredBusinessLines = businessLines.filter(
        businessLine => !businessLinesToNotInclude.includes(businessLine.id as string),
      );
      const businessLinesChartLines = filteredBusinessLines.map(businessLine => {
        return {
          ...chartLines[0],
          id: ++dataKeyCounter.current,
          dataType:
            chartLines[0].dataType && dataTypeHasBusinessLines(chartLines[0].dataType)
              ? chartLines[0].dataType
              : 'dist_cost',
          businessLine: [String(businessLine.id)],
        };
      });

      setChartLines(prev => (!addOnly ? [prev[0], ...businessLinesChartLines] : businessLinesChartLines));
    },
    [businessLines, chartLines, dataTypeHasBusinessLines],
  );

  const linesLinesToCompareItems = useMemo(() => {
    return chartLines.map((line, index) => ({ id: index as number, label: `Line ${index + 1}` }));
  }, [chartLines]);

  return (
    <SideWrapper
      title="Input Data"
      titleComponent={
        <Row className="pt-1">
          <Col>
            <Button
              variant="link"
              fontColor="warning-error"
              onClick={() => {
                setInitialFetch?.(false);
                setGraphTitle('');
                setGraphSubtitle('');
                setChartLines([]);
                setLinesToBeCompared([]);
                setChartStyle(initialChartStyle);
                setChangedScaleManually(false);
                setComparisonLinesConfig({ allToOne: false, type: 'none' });
                dataKeyCounter.current = 0;
              }}>
              Reset
            </Button>
          </Col>
          <Col>
            <Button variant="link" fontColor="primary" onClick={handleAddLine}>
              Add Line
            </Button>
          </Col>
        </Row>
      }
      isToShow={isToShow}
      close={close}>
      <div className="px-2 input-data-tab-content">
        <Button style={{ margin: '12px auto' }} onClick={handleApply}>
          Apply Filters
        </Button>
        {chartLines.length >= 2 && (
          <>
            <Row className="ms-1 align-items-end">
              <Col>
                <Typography fontSize="10px" color="detail-gray">
                  Comparison Lines Type
                </Typography>
                <DropdownBtn
                  id="choose-comparison-lines-type-dropdown"
                  styleType="filter"
                  placeholder="Select"
                  items={comparisonLinesTypeItems}
                  value={comparisonLinesTypeItems.find(item => item.id === comparisonLinesConfig.type)}
                  onClick={item => {
                    if (item) {
                      setComparisonLinesConfig(prev => ({ ...prev, type: String(item.id) }));
                      if (item.id === 'none') {
                        setLinesToBeCompared([]);
                      } else {
                        if (comparisonLinesConfig.allToOne) {
                          setLinesToBeCompared([{ line1: 0, line2: 1, id: ++dataKeyCounter.current }]);
                        } else {
                          setLinesToBeCompared(prev => prev.map(line => ({ ...line, type: String(item.id) })));
                        }
                      }
                    }
                  }}
                />
              </Col>
              <Col>
                <FormGroup style={{ display: 'flex' }}>
                  <FormControlLabel
                    control={<Checkbox />}
                    label={<span style={{ fontSize: '12px' }}>All to one</span>}
                    checked={comparisonLinesConfig.allToOne}
                    onChange={(_e, checked) => {
                      setComparisonLinesConfig(prev => ({ ...prev, allToOne: checked }));
                      if (checked) {
                        setLinesToBeCompared([{ line1: 0, line2: 1, id: ++dataKeyCounter.current }]);
                      } else {
                        setLinesToBeCompared([]);
                      }
                    }}
                  />
                </FormGroup>
              </Col>
            </Row>
            {comparisonLinesConfig.type !== 'none' && !comparisonLinesConfig.allToOne && (
              <>
                {linesToBeCompared.map((line, index) => (
                  <>
                    <Row key={line.id} className="mx-1 mt-5">
                      <Col>
                        <DropdownBtn
                          id={`choose-comparison-lines-${index}-dropdown`}
                          styleType="filter"
                          placeholder="Select"
                          items={linesLinesToCompareItems}
                          value={linesLinesToCompareItems.find(item => item.id === line.line1)}
                          onClick={item => {
                            if (item) {
                              setLinesToBeCompared(prev => {
                                if (linesLinesToCompareItems.length === 2) {
                                  const newLines = [...prev];
                                  newLines[index] = {
                                    ...newLines[index],
                                    line1: item.id as number,
                                    line2: linesLinesToCompareItems.find(i => i.id !== item.id)?.id as number,
                                  };
                                  return newLines;
                                } else {
                                  const newLines = [...prev];
                                  newLines[index] = {
                                    ...prev[index],
                                    line1: item.id as number,
                                  };
                                  return newLines;
                                }
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col
                        className="align-align-items-end"
                        style={{ maxWidth: 'max-content', textAlign: 'center', display: 'flex', alignItems: 'center' }}>
                        <Typography>To</Typography>
                      </Col>
                      <Col>
                        <DropdownBtn
                          id={`choose-comparison-lines-${index}-dropdown-2`}
                          styleType="filter"
                          placeholder="Select"
                          items={linesLinesToCompareItems.filter(item => item.id !== line.line1)}
                          value={linesLinesToCompareItems.find(item => item.id === line.line2)}
                          onClick={item => {
                            if (item) {
                              setLinesToBeCompared(prev => {
                                const newLines = [...prev];
                                newLines[index] = { ...newLines[index], line2: item.id as number };
                                return newLines;
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col
                        onClick={() => {
                          setLinesToBeCompared(prev => prev.filter((_, i) => i !== index));
                        }}
                        style={{ maxWidth: 'max-content', display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                        <DeleteOutline />
                      </Col>
                    </Row>

                    <Row key={(line?.id ?? 0) + 100} className="mx-1 mt-2">
                      <Col>
                        <DropdownBtn
                          id={`choose-comparison-lines-type-${index}-dropdown`}
                          styleType="filter"
                          placeholder="Select"
                          items={comparisonLinesTypeItems}
                          value={
                            linesToBeCompared[index].type
                              ? comparisonLinesTypeItems.find(item => item.id === linesToBeCompared[index].type)
                              : undefined
                          }
                          onClick={item => {
                            if (item) {
                              setLinesToBeCompared(prev => {
                                const newLines = [...prev];
                                newLines[index] = { ...newLines[index], type: item.id as string };
                                return newLines;
                              });
                            }
                          }}
                        />
                      </Col>
                      <Col>
                        <DropdownBtn
                          id={`choose-comparison-lines-scale-${index}-dropdown`}
                          styleType="filter"
                          placeholder="Select"
                          items={scaleOptions}
                          value={
                            linesToBeCompared[index].scale
                              ? scaleOptions.find(item => item.id === linesToBeCompared[index].scale)
                              : undefined
                          }
                          onClick={item => {
                            if (item) {
                              setLinesToBeCompared(prev => {
                                const newLines = [...prev];
                                newLines[index] = { ...newLines[index], scale: item.id as string };
                                return newLines;
                              });
                            }
                          }}
                        />
                      </Col>
                    </Row>
                  </>
                ))}

                <Row className="my-3 ms-1 justify-content-center">
                  <Button
                    variant="link"
                    fontColor="primary"
                    onClick={() =>
                      setLinesToBeCompared(prev => [
                        ...prev,
                        {
                          id: ++dataKeyCounter.current,
                          line1: undefined,
                          line2: undefined,
                          type: comparisonLinesConfig.type,
                          allToOne: comparisonLinesConfig.allToOne,
                          scale: scaleOptions[0].id as string,
                        },
                      ])
                    }>
                    Add Comparison
                  </Button>
                </Row>

                <div style={{ margin: '30px 0', width: '100%', height: '1px', background: 'var(--detail-gray)' }} />
              </>
            )}
          </>
        )}

        <Accordion activeKey={activeKey}>
          {chartLines.map((line, index) => (
            <div key={line.id}>
              <LineAccordionItem
                setLineCharts={setChartLines}
                onDeleteLine={handleDeleteLine}
                line={line}
                index={index}
                setActiveKey={setActiveKey}
                addBusinessLines={handleAddBusinessLines}
              />
              <hr />
            </div>
          ))}
        </Accordion>
      </div>
    </SideWrapper>
  );
};

export default InputDataTab;
