import CountryCodeAdmin from '../screens/country-code-admin';
import CurrencyAdmin from '../screens/currency-admin';
import DailyReportComponent from '../screens/daily-report';
import DistCostForecastSettings from '../screens/dist-cost-forecast-settings';
import ForecastChangesHistoryComponent from '../screens/forecast-changes-history';
import ImportForecast from '../screens/import-forecast';
import MacroGraph from '../screens/macro-graph';
import MaintenanceScreen from '../screens/maintenance';
import RankingReportComponent from '../screens/ranking-report';
import ScenarioForecast from '../screens/scenario-forecast';
import SummaryReportComponent from '../screens/summary-report';
import { Features } from './permissions-features';

export interface RouteData {
  label: string;
  path: string;
  feature: Features;
  /** If this is undefined, we have an unrestricted route */
  element: JSX.Element;
}

export const authenticatedRoutes: RouteData[] = [
  {
    label: 'Forecast',
    path: '/macro-graph',
    feature: Features.MacroGraphScreen,
    element: <MacroGraph />,
  },
  {
    label: 'Import forecast',
    path: '/import-forecast',
    feature: Features.ImportForecastScreen,
    element: <ImportForecast />,
  },
  {
    label: 'Forecast Changes History',
    path: '/forecast-changes-history',
    feature: Features.ForecastChangesHistoryScreen,
    element: <ForecastChangesHistoryComponent />,
  },
  {
    label: 'Reporting',
    path: '/reporting',
    feature: Features.DailyReportScreen,
    element: <MacroGraph />,
  },
  {
    label: 'Reporting Daily',
    path: '/reporting/daily',
    feature: Features.DailyReportScreen,
    element: <DailyReportComponent />,
  },
  {
    label: 'Reporting Summary',
    path: '/reporting/summary',
    feature: Features.SummaryReportScreen,
    element: <SummaryReportComponent />,
  },
  {
    label: 'Reporting Summary',
    path: '/reporting/ranking',
    feature: Features.RankingReportScreen,
    element: <RankingReportComponent />,
  },
  {
    label: 'Reporting Ranking',
    path: '/reporting/ranking',
    feature: Features.RankingReportScreen,
    element: <MacroGraph />,
  },
  {
    label: 'Maintenance',
    path: '/maintenance',
    feature: Features.MaintenanceScreen,
    element: <MaintenanceScreen />,
  },
  {
    label: 'Macro Maintenance',
    path: '/maintenance/macro-maintenance',
    feature: Features.MaintenanceScreen,
    element: <MaintenanceScreen />,
  },
  {
    label: 'Dist Cost Forecast Settings',
    path: '/maintenance/dist-cost-forecast-settings',
    feature: Features.DistCostForecastSettingsScreen,
    element: <DistCostForecastSettings />,
  },
  {
    label: 'Exchange Rate',
    path: '/exchange-rate-admin',
    feature: Features.ExchangeRatesScreen,
    element: <CurrencyAdmin />,
  },
  {
    label: 'Corp Code Admin',
    path: '/admin/corp-code-admin',
    feature: Features.CorpCodeScreen,
    element: <CountryCodeAdmin />,
  },
  {
    label: 'Scenario Forecast',
    path: '/scenario-forecast',
    feature: Features.ScenarioScreen,
    element: <ScenarioForecast />,
  },
];
