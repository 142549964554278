import { Button } from '@amway/react-components';
import ReactDataGrid from '@inovua/reactdatagrid-community';
import { TypeComputedProps } from '@inovua/reactdatagrid-community/types';
import moment from 'moment';
import { MutableRefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';
import { ConfirmGeneralAction } from '../../../components/core/alert-card/alert-cards';
import useCopyShortcut from '../../../components/custom-hooks/useCopyShortcut';
import { EditedValue, MaintenanceTableResponse } from '../../../resources/maintenance/maintenance-types';
import maintenanceService from '../../../resources/maintenance/maintenance.service';
import { corpReferHeaders, formatDate, formatRows } from '../utils';
import './index.scss';

interface Props {
  data: MaintenanceTableResponse;
  onResetData: () => void;
}

export default function CorpReferTable({ data, onResetData }: Props) {
  const [selectedCells, setSelectedCells] = useState<Record<string, boolean>>({});
  const [lockedCells, setLockedCells] = useState<string[]>([]);
  const [editedValues, setEditedValues] = useState<EditedValue[]>([]);
  const [isUpdating, setIsUpdating] = useState<boolean>(false);
  const [gridRef, setGridRef] = useState<MutableRefObject<TypeComputedProps | null> | null>(null);
  const [loadingNewValues, setLoadingNewValues] = useState<boolean>(false);

  const formattedData = useMemo(() => {
    const result = formatRows(data);

    return result;
  }, [data]);

  const lockUnlockValuesOnClick = (lock: boolean, cellIdsToBeEdited: string[]) => {
    if (lock) {
      setLockedCells(prev => [...prev, ...cellIdsToBeEdited]);
    } else {
      setLockedCells(prev => prev.filter(cell => !cellIdsToBeEdited.includes(cell)));
    }
  };

  // Used to render the floating menu when right click cell
  const renderRowContextMenu = (menuProps: any, { rowProps, cellProps }: any) => {
    const cellId = `${cellProps?.data.id},${cellProps?.id}`;
    const cellColumn = cellProps.id;

    if (cellColumn === 'year' || cellColumn === 'month' || cellColumn === 'corp') {
      return;
    }

    let cellIdsToBeEdited: string[] = [];
    if (Object.keys(selectedCells).length < 2) {
      cellIdsToBeEdited = [cellId];
    } else {
      cellIdsToBeEdited = Object.keys(selectedCells)
        .filter(cell => selectedCells[cell])
        .map(cell => cell);
    }

    // check if all selected cells are locked or unlocked
    const allSelectedCellsLocked = cellIdsToBeEdited.every(yearMonth => lockedCells.includes(yearMonth));
    const allSelectedCellsUnlocked = cellIdsToBeEdited.every(yearMonth => !lockedCells.includes(yearMonth));

    const shouldUnlock = lockedCells.includes(cellId);
    if (!allSelectedCellsLocked && !allSelectedCellsUnlocked) {
      menuProps.alignPositions = ['bl-tl'];
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: 'Lock all',
          onClick: () => lockUnlockValuesOnClick(true, cellIdsToBeEdited),
        },
        {
          label: 'Unlock all',
          onClick: () => lockUnlockValuesOnClick(false, cellIdsToBeEdited),
        },
      ];
    } else {
      menuProps.alignPositions = ['bl-tl'];
      menuProps.autoDismiss = true;
      menuProps.items = [
        {
          label: shouldUnlock ? 'Unlock' : 'Lock',
          onClick: () => lockUnlockValuesOnClick(!allSelectedCellsLocked, cellIdsToBeEdited),
        },
      ];
    }
  };

  useEffect(() => {
    const allCellsIds = data.allRows.flatMap(row =>
      data.allDataTypes.flatMap(dataType => {
        if (moment(`${row.year}-${row.month}`).isBefore(moment())) {
          return `${row.corp},${formatDate(`${row.year}-${row.month}`)},${dataType.dataType}`;
        } else {
          return [];
        }
      }),
    );
    setLockedCells(allCellsIds);
  }, []);

  const onCellSelectionChange = useCallback((newSelectedCells: Record<string, boolean>) => {
    const allColumnNames = Object.keys(newSelectedCells).map(key => key.split(',')[2]);

    if (allColumnNames.some(columnName => columnName === 'month' || columnName === 'year' || columnName === 'corp')) {
      return;
    }

    isEditingCell.current = false;
    setSelectedCells(newSelectedCells);
  }, []);

  const onEditComplete = useCallback(
    async ({ value, columnId, rowIndex }) => {
      const yr = formattedData[rowIndex].year;
      const month = formattedData[rowIndex].month;
      const corpCd = formattedData[rowIndex].corp;
      const yrMonth = formatDate(`${yr}-${month}`);
      const editedValue = {
        countryCd: corpCd,
        yrMo: Number(yrMonth),
        dataType: columnId,
        value: Number(value),
      };
      setEditedValues(prev => [...prev, editedValue]);

      formattedData[rowIndex][columnId] = value;

      isEditingCell.current = false;
      setTimeout(() => {
        setLoadingNewValues(false);
      }, 1000);
    },
    [formattedData],
  );

  const handleSaveChanges = () => {
    ConfirmGeneralAction(
      'Do you really want to save the changes?',
      'Changes saved successfully',
      'Error saving changes, please try again.',
      onUpdateValues,
    );
  };

  const onUpdateValues = useCallback(async () => {
    setIsUpdating(true);
    await maintenanceService.editValues(editedValues);
    setEditedValues([]);
    setIsUpdating(false);
  }, [editedValues]);

  const isEditingCell = useRef(false);
  // Function to handle key down event
  const handleKeyDown = useCallback(
    event => {
      if (
        (event.key === 'Enter' ||
          event.key === '1' ||
          event.key === '2' ||
          event.key === '3' ||
          event.key === '4' ||
          event.key === '5' ||
          event.key === '6' ||
          event.key === '7' ||
          event.key === '8' ||
          event.key === '9' ||
          event.key === '0') &&
        !isEditingCell.current &&
        !!gridRef?.current
      ) {
        if (selectedCells && Object.keys(selectedCells).length === 1) {
          const cellId = Object.entries(selectedCells)?.[0][0];
          const [countryCd, yrMo, columnId] = cellId.split(',');
          const rowId = `${countryCd},${yrMo}`;

          if (!formattedData) {
            return;
          }

          const rowIndex = formattedData.findIndex(row => row.id === rowId);
          if (gridRef.current.startEdit && rowIndex > -1) {
            const value = event.key === 'Enter' ? undefined : event.key;
            gridRef?.current?.startEdit?.({ rowIndex, columnId, value });
          }
        }
      }
    },
    [formattedData, gridRef, selectedCells],
  );

  // Attach the event listener once the grid is ready
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  const onEditStart = useCallback(
    ({ rowIndex, columnId }) => {
      const newSelectedCell = {
        [`${formattedData[rowIndex].corp},${formattedData[rowIndex].year}-${formattedData[rowIndex].month},${columnId}`]:
          true,
      };

      isEditingCell.current = true;
      setSelectedCells(newSelectedCell);
    },
    [formattedData],
  );

  const formatSelectedCellsForClipboard = useCallback(() => {
    const selectedRows: string[][] = [];

    Object.keys(selectedCells).forEach(key => {
      const [countryCd, yrMo, columnId] = key.split(',');
      const rowId = `${countryCd},${yrMo}`;
      const rowIndex = formattedData.findIndex(row => row.id === rowId);
      const value = formattedData.find(row => row.id === rowId)?.[columnId];

      // Create a new row if it doesn't exist
      if (!selectedRows[rowIndex]) {
        selectedRows[rowIndex] = [];
      }

      selectedRows[rowIndex].push(value);
    });

    const result = selectedRows.map(row => row.join('\t')).join('\n');

    return result;
  }, [formattedData, selectedCells]);

  useCopyShortcut(formatSelectedCellsForClipboard());

  return (
    <Row>
      <div style={{ display: 'flex', width: '100%', justifyContent: 'end', gap: 15, marginBottom: 25 }}>
        <Button
          disabled={isUpdating || editedValues.length < 1}
          loading={isUpdating}
          backgroundColor="warning-success"
          onClick={handleSaveChanges}>
          Update
        </Button>
        <Button variant="link" fontColor="warning-error" onClick={onResetData}>
          Reset
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'start',
        }}>
        <table
          style={{
            height: '100%',
          }}>
          <thead>
            <tr>
              <th className="ref-table-cell-style">Corp</th>
              <th className="ref-table-cell-style">Year</th>
              <th className="ref-table-cell-style">Month</th>
            </tr>
          </thead>
          <tbody>
            {formattedData.map((row, index) => (
              <tr
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#f8f8f8' : 'var(--white)',
                }}>
                <td className="ref-table-cell-style">{row.corp}</td>
                <td className="ref-table-cell-style">{row.year}</td>
                <td className="ref-table-cell-style">{row.month}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <ReactDataGrid
          onReady={setGridRef}
          idProperty="id"
          style={{ height: 'calc(100% + 5px)' }}
          reorderColumns={false}
          dataSource={formattedData}
          columns={corpReferHeaders(lockedCells, editedValues, loadingNewValues, data.allDataTypes)}
          editable
          cellSelection={selectedCells}
          onCellSelectionChange={onCellSelectionChange}
          renderRowContextMenu={renderRowContextMenu}
          onEditStart={onEditStart}
          onEditComplete={onEditComplete}
          showColumnMenuLockOptions={false}
          showColumnMenuGroupOptions={false}
          showColumnMenuFilterOptions={false}
          showGroupSummaryRow={false}
          showColumnMenuSortOptions={false}
          sortable={false}
          autoFocusOnEditComplete
        />
      </div>
    </Row>
  );
}
